
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
  skip
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.21.1
 * Query Engine version: bf0e5e8a04cada8225617067eaa03d041e2bba36
 */
Prisma.prismaVersion = {
  client: "5.21.1",
  engine: "bf0e5e8a04cada8225617067eaa03d041e2bba36"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}



/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.Aiscout_affiliationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  entry_code: 'entry_code',
  unique_entry_code: 'unique_entry_code',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_affiliation_tagScalarFieldEnum = {
  id: 'id',
  affiliation_id: 'affiliation_id',
  name: 'name',
  value: 'value',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_age_groupScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created',
  modified: 'modified',
  age: 'age',
  national_rating_system_multiplier: 'national_rating_system_multiplier',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_countryScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created',
  modified: 'modified',
  flag_relative_path: 'flag_relative_path',
  suggested_sort_order: 'suggested_sort_order',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_countyScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created',
  modified: 'modified',
  country_id: 'country_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_ethnicityScalarFieldEnum = {
  id: 'id',
  name: 'name',
  sort_order: 'sort_order',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_nationalityScalarFieldEnum = {
  id: 'id',
  name: 'name',
  icon_relative_path: 'icon_relative_path',
  legacy_sort_order: 'legacy_sort_order',
  suggested_sort_order: 'suggested_sort_order',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_passport_authorityScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created',
  modified: 'modified',
  icon_relative_path: 'icon_relative_path',
  suggested_sort_order: 'suggested_sort_order',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_positionScalarFieldEnum = {
  id: 'id',
  name: 'name',
  abbreviation: 'abbreviation',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_sign_up_tierScalarFieldEnum = {
  id: 'id',
  country_id: 'country_id',
  min_age: 'min_age',
  max_age: 'max_age',
  sign_up_tier_type: 'sign_up_tier_type',
  created: 'created',
  modified: 'modified',
  term_agreement_id: 'term_agreement_id',
  is_enabled: 'is_enabled',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_term_agreementScalarFieldEnum = {
  id: 'id',
  resource_url: 'resource_url',
  blurb: 'blurb',
  accept_button_text: 'accept_button_text',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_playerScalarFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  legacy_user_id: 'legacy_user_id',
  date_of_birth: 'date_of_birth',
  email: 'email',
  gender: 'gender',
  height: 'height',
  personal_statement: 'personal_statement',
  phone: 'phone',
  preferred_foot: 'preferred_foot',
  profile_image_path: 'profile_image_path',
  weight: 'weight',
  secondary_contact_relationship_type: 'secondary_contact_relationship_type',
  secondary_contact_mobile: 'secondary_contact_mobile',
  secondary_contact_name: 'secondary_contact_name',
  secondary_contact_email: 'secondary_contact_email',
  father_height: 'father_height',
  mother_height: 'mother_height',
  city: 'city',
  post_code: 'post_code',
  ethnicity_id: 'ethnicity_id',
  national_rating_system_score: 'national_rating_system_score',
  custom_home_county: 'custom_home_county',
  home_county_id: 'home_county_id',
  home_country_id: 'home_country_id',
  pro_club_signed_type: 'pro_club_signed_type',
  signed_pro_club_id: 'signed_pro_club_id',
  is_onboarding_complete: 'is_onboarding_complete',
  is_eligible_for_nrs: 'is_eligible_for_nrs',
  father_email: 'father_email',
  father_name: 'father_name',
  father_phone: 'father_phone',
  father_home_country_id: 'father_home_country_id',
  mother_email: 'mother_email',
  mother_name: 'mother_name',
  mother_phone: 'mother_phone',
  mother_home_country_id: 'mother_home_country_id',
  pro_club_signed_date: 'pro_club_signed_date',
  rated_nrs_drill_count: 'rated_nrs_drill_count',
  tps_cognitive: 'tps_cognitive',
  tps_physical: 'tps_physical',
  tps_score_ai_scout: 'tps_score_ai_scout',
  tps_technical: 'tps_technical',
  academy_member_pro_club_id: 'academy_member_pro_club_id',
  guardian_email: 'guardian_email',
  guardian_name: 'guardian_name',
  guardian_phone: 'guardian_phone',
  guardian_home_country_id: 'guardian_home_country_id',
  is_limited_access: 'is_limited_access',
  home_latlng: 'home_latlng',
  sit_height: 'sit_height',
  primary_playing_country_id: 'primary_playing_country_id',
  name: 'name',
  skip_current_team_question: 'skip_current_team_question',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_affiliationScalarFieldEnum = {
  player_id: 'player_id',
  affiliation_id: 'affiliation_id',
  created: 'created',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_countryScalarFieldEnum = {
  player_id: 'player_id',
  country_id: 'country_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_highlight_requestScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  url: 'url',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_nationalityScalarFieldEnum = {
  player_id: 'player_id',
  nationality_id: 'nationality_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_passport_authorityScalarFieldEnum = {
  player_id: 'player_id',
  passport_authority_id: 'passport_authority_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_positionScalarFieldEnum = {
  player_id: 'player_id',
  position_id: 'position_id',
  is_primary: 'is_primary',
  created: 'created',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_sign_up_agreementScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  term_agreement_id: 'term_agreement_id',
  has_guardian_consent: 'has_guardian_consent',
  sign_up_tier_type: 'sign_up_tier_type',
  guardian_consented_time: 'guardian_consented_time',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_sign_up_agreement_reasonScalarFieldEnum = {
  id: 'id',
  player_sign_up_agreement_id: 'player_sign_up_agreement_id',
  country_id: 'country_id',
  age: 'age',
  date_of_birth: 'date_of_birth',
  is_fallback_agreement: 'is_fallback_agreement',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_reelScalarFieldEnum = {
  id: 'id',
  description: 'description',
  player_id: 'player_id',
  share_type: 'share_type',
  media_type: 'media_type',
  is_ready: 'is_ready',
  is_promoted: 'is_promoted',
  is_public_post: 'is_public_post',
  media_relative_path: 'media_relative_path',
  title: 'title',
  media_relative_path_hash_code: 'media_relative_path_hash_code',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_scoutScalarFieldEnum = {
  id: 'id',
  legacy_user_id: 'legacy_user_id',
  created: 'created',
  modified: 'modified',
  email: 'email',
  name: 'name',
  profile_image_path: 'profile_image_path',
  pro_club_id: 'pro_club_id',
  dbs_expiry: 'dbs_expiry',
  verified_status: 'verified_status',
  scout_status: 'scout_status',
  scout_expertise_id: 'scout_expertise_id',
  club_contact_name: 'club_contact_name',
  club_contact_phone_number: 'club_contact_phone_number',
  user_id: 'user_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_scout_achievementScalarFieldEnum = {
  id: 'id',
  name: 'name',
  scout_id: 'scout_id',
  sort_order: 'sort_order',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_scout_business_cardScalarFieldEnum = {
  id: 'id',
  scout_id: 'scout_id',
  email: 'email',
  phone_number: 'phone_number',
  standard_note: 'standard_note',
  affiliation_id: 'affiliation_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_scout_expertiseScalarFieldEnum = {
  id: 'id',
  created: 'created',
  modified: 'modified',
  expertise: 'expertise',
  expertise_type: 'expertise_type',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_scout_player_followerScalarFieldEnum = {
  player_id: 'player_id',
  scout_id: 'scout_id',
  created: 'created',
  position_id: 'position_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_reportScalarFieldEnum = {
  id: 'id',
  title: 'title',
  source_url: 'source_url',
  scout_id: 'scout_id',
  draft_id: 'draft_id',
  participant_id: 'participant_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_report_draftScalarFieldEnum = {
  id: 'id',
  title: 'title',
  start_time: 'start_time',
  scout_id: 'scout_id',
  local_time_zone_id: 'local_time_zone_id',
  home_team_participant_id: 'home_team_participant_id',
  away_team_participant_id: 'away_team_participant_id',
  status: 'status',
  batch_transcription_status: 'batch_transcription_status',
  audio_transcription_job_id: 'audio_transcription_job_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_player_report_participantScalarFieldEnum = {
  id: 'id',
  name: 'name',
  age: 'age',
  participant_type: 'participant_type',
  player_id: 'player_id',
  team_id: 'team_id',
  age_group_id: 'age_group_id',
  client_side_id: 'client_side_id',
  draft_id: 'draft_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_summary_reportScalarFieldEnum = {
  id: 'id',
  scout_id: 'scout_id',
  summary_report_draft_id: 'summary_report_draft_id',
  title: 'title',
  source_url: 'source_url',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_summary_report_draftScalarFieldEnum = {
  id: 'id',
  title: 'title',
  status: 'status',
  scout_id: 'scout_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_academy_teamScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  pro_club_division_id: 'pro_club_division_id',
  age_group_id: 'age_group_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_academy_team_playerScalarFieldEnum = {
  id: 'id',
  academy_team_id: 'academy_team_id',
  player_id: 'player_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_coachScalarFieldEnum = {
  id: 'id',
  legacy_user_id: 'legacy_user_id',
  first_name: 'first_name',
  last_name: 'last_name',
  profile_image_path: 'profile_image_path',
  pro_club_id: 'pro_club_id',
  user_id: 'user_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_pro_clubScalarFieldEnum = {
  id: 'id',
  logo_relative_path: 'logo_relative_path',
  name: 'name',
  primary_venue_name: 'primary_venue_name',
  suggested_sort_order: 'suggested_sort_order',
  background_image_path: 'background_image_path',
  theme_color_code: 'theme_color_code',
  training_ground_latlng: 'training_ground_latlng',
  training_ground_name: 'training_ground_name',
  country_id: 'country_id',
  button_primary_color_code: 'button_primary_color_code',
  button_text_primary_color_code: 'button_text_primary_color_code',
  is_dark_status_bar_trial_submission: 'is_dark_status_bar_trial_submission',
  text_primary_color_code: 'text_primary_color_code',
  client_id: 'client_id',
  pro_league_id: 'pro_league_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_teamScalarFieldEnum = {
  id: 'id',
  age_group_id: 'age_group_id',
  amateur_club_id: 'amateur_club_id',
  division_id: 'division_id',
  pro_club_id: 'pro_club_id',
  logo_relative_path: 'logo_relative_path',
  name: 'name',
  team_contact_name: 'team_contact_name',
  team_contact_number: 'team_contact_number',
  team_status_type: 'team_status_type',
  team_type_id: 'team_type_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_team_playerScalarFieldEnum = {
  id: 'id',
  team_id: 'team_id',
  player_id: 'player_id',
  coach_name: 'coach_name',
  email: 'email',
  mobile: 'mobile',
  start_date: 'start_date',
  end_date: 'end_date',
  is_main_team: 'is_main_team',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_team_typeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  sort_order: 'sort_order',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_training_sessionScalarFieldEnum = {
  id: 'id',
  academy_team_id: 'academy_team_id',
  trial_id: 'trial_id',
  coach_id: 'coach_id',
  name: 'name',
  subtitle: 'subtitle',
  is_enabled: 'is_enabled',
  completed_time: 'completed_time',
  start_time: 'start_time',
  banner_image_path: 'banner_image_path',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_training_session_playerScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  training_session_id: 'training_session_id',
  availability_type: 'availability_type',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drillScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  badges_path: 'badges_path',
  title: 'title',
  short_description: 'short_description',
  background_image_path: 'background_image_path',
  measurement_fact_type: 'measurement_fact_type',
  trial_type: 'trial_type',
  national_rating_system_multiplier: 'national_rating_system_multiplier',
  entry_limit: 'entry_limit',
  demo_video_path: 'demo_video_path',
  drill_estimated_time: 'drill_estimated_time',
  preview_image_path: 'preview_image_path',
  cover_image_path: 'cover_image_path',
  minimum_required_score: 'minimum_required_score',
  subtitle: 'subtitle',
  analysis_interpreter_id: 'analysis_interpreter_id',
  is_enabled: 'is_enabled',
  landscape_preview_image_path: 'landscape_preview_image_path',
  primary_skill_dimension: 'primary_skill_dimension',
  square_preview_image_path: 'square_preview_image_path',
  landscape_preview_image_title: 'landscape_preview_image_title',
  drill_identity_id: 'drill_identity_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_entryScalarFieldEnum = {
  id: 'id',
  drill_id: 'drill_id',
  player_id: 'player_id',
  coach_id: 'coach_id',
  trial_id: 'trial_id',
  age_group_id: 'age_group_id',
  score: 'score',
  measurement_fact_value: 'measurement_fact_value',
  national_rating_system_score: 'national_rating_system_score',
  rated_at: 'rated_at',
  rated_by_scout_id: 'rated_by_scout_id',
  manual_turk_submission: 'manual_turk_submission',
  custom_error_code: 'custom_error_code',
  custom_error_message: 'custom_error_message',
  custom_error_title: 'custom_error_title',
  training_session_id: 'training_session_id',
  feedback_type: 'feedback_type',
  original_video_path: 'original_video_path',
  original_video_suffix: 'original_video_suffix',
  analysed_video_path: 'analysed_video_path',
  entry_status: 'entry_status',
  expiry_time: 'expiry_time',
  drill_analyzed_notification: 'drill_analyzed_notification',
  workflow_id: 'workflow_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_entry_measurementScalarFieldEnum = {
  value: 'value',
  entry_measurement_type_id: 'entry_measurement_type_id',
  drill_entry_id: 'drill_entry_id',
  created: 'created',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_entry_measurement_typeScalarFieldEnum = {
  drill_id: 'drill_id',
  entry_measurement_type_id: 'entry_measurement_type_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_entry_ratingScalarFieldEnum = {
  rating: 'rating',
  entry_rating_type_id: 'entry_rating_type_id',
  drill_entry_id: 'drill_entry_id',
  created: 'created',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_entry_rating_typeScalarFieldEnum = {
  entry_rating_type_id: 'entry_rating_type_id',
  drill_id: 'drill_id',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_drill_library_itemScalarFieldEnum = {
  id: 'id',
  drill_id: 'drill_id',
  display_in_shuffle: 'display_in_shuffle',
  hero_section_sort_order: 'hero_section_sort_order',
  badge_path: 'badge_path',
  gender: 'gender',
  minimum_age: 'minimum_age',
  stage_one_sort_order: 'stage_one_sort_order',
  sort_order: 'sort_order',
  maximum_age: 'maximum_age',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_entry_measurement_typeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  turk_ui_text: 'turk_ui_text',
  measurement_unit: 'measurement_unit',
  is_available_to_player: 'is_available_to_player',
  is_available_to_turk: 'is_available_to_turk',
  display_unit: 'display_unit',
  high_is_best: 'high_is_best',
  analysis_interpreter_measurement_id: 'analysis_interpreter_measurement_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_entry_rating_typeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  is_available_to_player: 'is_available_to_player',
  is_available_to_scout: 'is_available_to_scout',
  is_available_to_turk: 'is_available_to_turk',
  is_available_to_coach: 'is_available_to_coach',
  analysis_interpreter_rating_id: 'analysis_interpreter_rating_id',
  turk_ui_text: 'turk_ui_text',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_trialScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  trial_category_id: 'trial_category_id',
  sort_order: 'sort_order',
  short_description: 'short_description',
  legacy_banner_image_path: 'legacy_banner_image_path',
  is_enabled: 'is_enabled',
  is_unlocked: 'is_unlocked',
  name: 'name',
  info_screen_title: 'info_screen_title',
  name_for_scout_app: 'name_for_scout_app',
  academy_id: 'academy_id',
  banner_subtitle: 'banner_subtitle',
  banner_title: 'banner_title',
  completion_type: 'completion_type',
  is_drills_unlocked: 'is_drills_unlocked',
  landscape_preview_image_path: 'landscape_preview_image_path',
  list_item_subtitle: 'list_item_subtitle',
  square_preview_image_path: 'square_preview_image_path',
  banner_image_path: 'banner_image_path',
  subtitle: 'subtitle',
  is_open: 'is_open',
  square_primary_image_path: 'square_primary_image_path',
  square_primary_image_title: 'square_primary_image_title',
  drills_title: 'drills_title',
  is_stage_one_drills_disabled: 'is_stage_one_drills_disabled',
  open_datetime: 'open_datetime',
  close_datetime: 'close_datetime',
  rule_expression: 'rule_expression',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_trial_categoryScalarFieldEnum = {
  id: 'id',
  title: 'title',
  banner_image_path: 'banner_image_path',
  trial_type: 'trial_type',
  sort_order: 'sort_order',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_trial_drillScalarFieldEnum = {
  id: 'id',
  drill_id: 'drill_id',
  trial_id: 'trial_id',
  sort_order: 'sort_order',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Aiscout_user_settingScalarFieldEnum = {
  is_enabled: 'is_enabled',
  user_id: 'user_id',
  setting_id: 'setting_id',
  created: 'created',
  modified: 'modified',
  tenant_id: 'tenant_id'
};

exports.Prisma.Control_centre_pro_clubsScalarFieldEnum = {
  pro_club_id: 'pro_club_id',
  name: 'name',
  created: 'created',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Control_centre_pro_club_settingsScalarFieldEnum = {
  pro_club_id: 'pro_club_id',
  age_group_calculation_method: 'age_group_calculation_method',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Control_centre_pro_club_player_metadata_schemasScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  field_name: 'field_name',
  field_type: 'field_type',
  example_value: 'example_value',
  created: 'created',
  modified: 'modified',
  sort_order: 'sort_order'
};

exports.Prisma.Drill_metric_graphsScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  name: 'name',
  age_min: 'age_min',
  age_max: 'age_max',
  created: 'created',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Drill_metric_graph_metricsScalarFieldEnum = {
  id: 'id',
  drill_metric_graph_id: 'drill_metric_graph_id',
  metric_id: 'metric_id',
  created: 'created'
};

exports.Prisma.Drill_metricsScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  name: 'name',
  type: 'type',
  is_primary: 'is_primary',
  is_secondary: 'is_secondary',
  benchmark_value: 'benchmark_value',
  created: 'created',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  manual_observation_enabled: 'manual_observation_enabled'
};

exports.Prisma.Drill_metric_drillsScalarFieldEnum = {
  id: 'id',
  drill_metric_id: 'drill_metric_id',
  drill_id: 'drill_id',
  drill_id_aiscout: 'drill_id_aiscout',
  created: 'created'
};

exports.Prisma.Luzmo_report_role_permissionsScalarFieldEnum = {
  report_id: 'report_id',
  user_role_id: 'user_role_id'
};

exports.Prisma.Luzmo_report_sectionsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  sort_order: 'sort_order'
};

exports.Prisma.Luzmo_reportsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  slug: 'slug',
  pro_club_id: 'pro_club_id',
  section_id: 'section_id',
  report_type_id: 'report_type_id',
  sort_order: 'sort_order',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Hawkin_dynamics_metricsScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Hawkin_dynamics_tagsScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Hawkin_dynamics_testsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  test_type_id: 'test_type_id'
};

exports.Prisma.Match_report_decision_status_entriesScalarFieldEnum = {
  id: 'id',
  summary_report_draft_id: 'summary_report_draft_id',
  summary_report_id: 'summary_report_id',
  status_id: 'status_id',
  summary_report_draft_id_aiscout: 'summary_report_draft_id_aiscout',
  summary_report_id_aiscout: 'summary_report_id_aiscout'
};

exports.Prisma.Match_report_decision_statusesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  sort_order: 'sort_order',
  should_send_email: 'should_send_email',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Performance_lab_hawkin_dynamics_measurementsScalarFieldEnum = {
  id: 'id',
  test_id: 'test_id',
  metric_id: 'metric_id',
  measurement_id: 'measurement_id',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  measurement_type_id: 'measurement_type_id'
};

exports.Prisma.Performance_lab_hawkin_dynamics_measurements_tagsScalarFieldEnum = {
  id: 'id',
  hawkin_dynamic_measurement_id: 'hawkin_dynamic_measurement_id',
  tag_id: 'tag_id'
};

exports.Prisma.Performance_lab_measurement_formulasScalarFieldEnum = {
  measurement_id: 'measurement_id',
  formula: 'formula',
  measurement_type_id: 'measurement_type_id'
};

exports.Prisma.Performance_lab_measurementsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  entry_rating_type_id: 'entry_rating_type_id',
  entry_measurement_type_id: 'entry_measurement_type_id'
};

exports.Prisma.Performance_lab_measurement_scoring_lookupsScalarFieldEnum = {
  id: 'id',
  measurement_id: 'measurement_id',
  age_group_id: 'age_group_id',
  age_group_id_aiscout: 'age_group_id_aiscout',
  min_age: 'min_age',
  max_age: 'max_age',
  lower_value: 'lower_value',
  upper_value: 'upper_value',
  rating: 'rating',
  gender: 'gender',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  measurement_type_id: 'measurement_type_id'
};

exports.Prisma.Performance_lab_player_maturationScalarFieldEnum = {
  id: 'id',
  session_id: 'session_id',
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout',
  height: 'height',
  weight: 'weight',
  sit_height: 'sit_height',
  actual_sit_height: 'actual_sit_height',
  collection_date: 'collection_date',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_player_reflexion_test_attemptsScalarFieldEnum = {
  id: 'id',
  session_id: 'session_id',
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout',
  reflexion_test_id: 'reflexion_test_id',
  is_official: 'is_official',
  raw_score: 'raw_score',
  reflexion_score: 'reflexion_score',
  created: 'created'
};

exports.Prisma.Performance_lab_reflexion_test_metricsScalarFieldEnum = {
  id: 'id',
  reflexion_test_id: 'reflexion_test_id',
  measurement_id: 'measurement_id',
  reflexion_score_type: 'reflexion_score_type',
  metric_id: 'metric_id'
};

exports.Prisma.Performance_lab_reflexion_testsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  assessment_type: 'assessment_type',
  instructions: 'instructions',
  instructions_video_path: 'instructions_video_path',
  sort_order: 'sort_order'
};

exports.Prisma.Performance_lab_report_cardsScalarFieldEnum = {
  id: 'id',
  card_type: 'card_type',
  name: 'name',
  trial_type: 'trial_type',
  description: 'description',
  display_name: 'display_name',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  created: 'created'
};

exports.Prisma.Performance_lab_report_card_benchmarksScalarFieldEnum = {
  id: 'id',
  card_id: 'card_id',
  gender: 'gender',
  min_age: 'min_age',
  max_age: 'max_age',
  rating: 'rating',
  benchmark_type_id: 'benchmark_type_id',
  position_group_id: 'position_group_id'
};

exports.Prisma.Performance_lab_report_card_benchmark_typesScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Performance_lab_report_card_tipsScalarFieldEnum = {
  id: 'id',
  card_id: 'card_id',
  tip: 'tip',
  sort_order: 'sort_order',
  created: 'created'
};

exports.Prisma.Performance_lab_report_card_measurementsScalarFieldEnum = {
  id: 'id',
  card_id: 'card_id',
  measurement_id: 'measurement_id',
  min_value: 'min_value',
  max_value: 'max_value',
  measurement_type_id: 'measurement_type_id'
};

exports.Prisma.Performance_lab_report_card_slotsScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Performance_lab_session_measurementsScalarFieldEnum = {
  id: 'id',
  session_id: 'session_id',
  measurement_id: 'measurement_id',
  is_additional: 'is_additional',
  sort_order: 'sort_order'
};

exports.Prisma.Performance_lab_session_playersScalarFieldEnum = {
  session_id: 'session_id',
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout',
  session_number: 'session_number',
  created: 'created',
  is_removed: 'is_removed'
};

exports.Prisma.Performance_lab_session_report_card_slotsScalarFieldEnum = {
  id: 'id',
  session_id: 'session_id',
  card_id: 'card_id',
  slot_id: 'slot_id',
  is_additional: 'is_additional'
};

exports.Prisma.Performance_lab_session_themesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created'
};

exports.Prisma.Performance_lab_sessionsScalarFieldEnum = {
  id: 'id',
  session_date: 'session_date',
  trial_id: 'trial_id',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  default_profile_image_path: 'default_profile_image_path',
  age_group_id: 'age_group_id',
  age_group_id_aiscout: 'age_group_id_aiscout',
  name: 'name',
  affiliation_code: 'affiliation_code',
  affiliation_id: 'affiliation_id',
  is_template: 'is_template',
  from_template_id: 'from_template_id',
  is_active: 'is_active',
  pro_club_signed_type: 'pro_club_signed_type',
  gender: 'gender',
  session_type: 'session_type',
  season_id: 'season_id',
  station_group_id: 'station_group_id',
  created: 'created',
  modified: 'modified',
  theme_id: 'theme_id'
};

exports.Prisma.Player_game_clipsScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  title: 'title',
  url: 'url',
  duration: 'duration',
  is_embedded: 'is_embedded',
  player_id_aiscout: 'player_id_aiscout'
};

exports.Prisma.Player_management_action_logScalarFieldEnum = {
  id: 'id',
  entry_id: 'entry_id',
  action_id: 'action_id',
  user_id: 'user_id',
  created: 'created'
};

exports.Prisma.Player_management_actionsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description'
};

exports.Prisma.Player_management_entriesScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  list_id: 'list_id',
  sort_order: 'sort_order',
  user_id: 'user_id',
  is_complete: 'is_complete',
  created: 'created',
  player_id_aiscout: 'player_id_aiscout'
};

exports.Prisma.Player_management_entry_logScalarFieldEnum = {
  id: 'id',
  entry_id: 'entry_id',
  list_id: 'list_id',
  user_id: 'user_id',
  created: 'created'
};

exports.Prisma.Player_management_entry_tagsScalarFieldEnum = {
  id: 'id',
  entry_id: 'entry_id',
  tag_id: 'tag_id',
  created: 'created'
};

exports.Prisma.Player_management_listsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  list_type_id: 'list_type_id',
  pro_club_id: 'pro_club_id',
  created: 'created',
  sort_order: 'sort_order',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Player_management_list_type_rule_actionsScalarFieldEnum = {
  id: 'id',
  list_type_id: 'list_type_id',
  action_id: 'action_id',
  is_required: 'is_required',
  is_option: 'is_option'
};

exports.Prisma.Player_management_list_type_rule_notificationsScalarFieldEnum = {
  id: 'id',
  list_type_id: 'list_type_id',
  notification_id: 'notification_id',
  player_notifications_id: 'player_notifications_id'
};

exports.Prisma.Player_management_list_type_rule_tags_typesScalarFieldEnum = {
  id: 'id',
  list_type_id: 'list_type_id',
  tag_type_id: 'tag_type_id',
  is_required: 'is_required',
  is_option: 'is_option'
};

exports.Prisma.Player_management_list_typesScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Player_management_notificationsScalarFieldEnum = {
  id: 'id',
  notification_type_id: 'notification_type_id'
};

exports.Prisma.Player_management_notification_typesScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Player_management_tagsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  sort_order: 'sort_order',
  tag_type_id: 'tag_type_id',
  colour: 'colour',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Player_management_tags_typesScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Player_manual_observationsScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout',
  user_id: 'user_id',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  event_name: 'event_name',
  collection_date: 'collection_date',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Player_manual_observation_entriesScalarFieldEnum = {
  manual_observation_id: 'manual_observation_id',
  drill_metric_id: 'drill_metric_id',
  score: 'score',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Player_metadataScalarFieldEnum = {
  player_id_aiscout: 'player_id_aiscout',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  metadata: 'metadata',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Player_notesScalarFieldEnum = {
  id: 'id',
  player_id: 'player_id',
  note: 'note',
  user_id: 'user_id',
  pro_club_id: 'pro_club_id',
  created: 'created',
  player_id_aiscout: 'player_id_aiscout',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Player_notificationsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  generic_message: 'generic_message',
  pro_club_message: 'pro_club_message',
  notify_job_type: 'notify_job_type',
  notify_job_type_aiscout: 'notify_job_type_aiscout',
  is_widget: 'is_widget'
};

exports.Prisma.Player_position_group_to_player_positionScalarFieldEnum = {
  position_group_id: 'position_group_id',
  position_id: 'position_id',
  position_id_aiscout: 'position_id_aiscout'
};

exports.Prisma.Player_position_groupsScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Player_report_status_entriesScalarFieldEnum = {
  id: 'id',
  player_report_participant_id: 'player_report_participant_id',
  player_id: 'player_id',
  status_id: 'status_id',
  player_id_aiscout: 'player_id_aiscout',
  player_report_participant_id_aiscout: 'player_report_participant_id_aiscout'
};

exports.Prisma.Player_report_statusesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  sort_order: 'sort_order',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Player_report_voice_note_tag_entriesScalarFieldEnum = {
  id: 'id',
  voice_note_id: 'voice_note_id',
  tag_id: 'tag_id',
  voice_note_id_aiscout: 'voice_note_id_aiscout'
};

exports.Prisma.Player_report_voice_note_tagsScalarFieldEnum = {
  id: 'id',
  name: 'name'
};

exports.Prisma.Pro_club_partnership_programme_playersScalarFieldEnum = {
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout'
};

exports.Prisma.Recruitment_centre_rule_engineScalarFieldEnum = {
  id: 'id',
  pro_club_id: 'pro_club_id',
  filter_type: 'filter_type',
  comparison_operator: 'comparison_operator',
  created: 'created',
  logical_operator: 'logical_operator',
  filter_value: 'filter_value',
  filter_value_aiscout: 'filter_value_aiscout',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.Saved_searchesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  user_id: 'user_id',
  pro_club_id: 'pro_club_id',
  search_params: 'search_params',
  created: 'created',
  last_used: 'last_used',
  is_valid: 'is_valid',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  search_params_aiscout: 'search_params_aiscout'
};

exports.Prisma.SeasonsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  is_active: 'is_active'
};

exports.Prisma.Super_turk_drillsScalarFieldEnum = {
  drill_id: 'drill_id',
  minimum_super_turk_score: 'minimum_super_turk_score',
  drill_id_aiscout: 'drill_id_aiscout'
};

exports.Prisma.Turk_benchmark_affiliationsScalarFieldEnum = {
  affiliation_id: 'affiliation_id',
  affiliation_id_aiscout: 'affiliation_id_aiscout'
};

exports.Prisma.Turk_benchmark_affiliation_usersScalarFieldEnum = {
  user_id: 'user_id',
  affiliation_code_id: 'affiliation_code_id',
  affiliation_code_id_aiscout: 'affiliation_code_id_aiscout'
};

exports.Prisma.Turk_error_code_groupsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  sort_order: 'sort_order'
};

exports.Prisma.Turk_error_codesScalarFieldEnum = {
  id: 'id',
  app_code_id: 'app_code_id',
  app_code_id_aiscout: 'app_code_id_aiscout',
  custom_code_id: 'custom_code_id',
  message: 'message',
  error_code_group_id: 'error_code_group_id',
  sort_order: 'sort_order'
};

exports.Prisma.Turk_queueScalarFieldEnum = {
  id: 'id',
  drill_entry_id: 'drill_entry_id',
  created: 'created',
  is_super_turk: 'is_super_turk',
  super_turk_details: 'super_turk_details',
  is_turk_hold: 'is_turk_hold',
  user_id: 'user_id',
  drill_entry_id_aiscout: 'drill_entry_id_aiscout'
};

exports.Prisma.User_pro_club_accessScalarFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  pro_club_id: 'pro_club_id',
  is_active: 'is_active',
  pro_club_id_aiscout: 'pro_club_id_aiscout'
};

exports.Prisma.User_permission_groupsScalarFieldEnum = {
  user_id: 'user_id',
  name: 'name'
};

exports.Prisma.User_rolesScalarFieldEnum = {
  id: 'id',
  role_mapping_id: 'role_mapping_id',
  name: 'name'
};

exports.Prisma.User_settingsScalarFieldEnum = {
  user_id: 'user_id',
  unit_system: 'unit_system'
};

exports.Prisma.UsersScalarFieldEnum = {
  id: 'id',
  name: 'name',
  email: 'email',
  scout_id: 'scout_id',
  created: 'created',
  access_token: 'access_token',
  refresh_token: 'refresh_token',
  role_id: 'role_id',
  business_card_affiliation_id: 'business_card_affiliation_id',
  scout_id_aiscout: 'scout_id_aiscout',
  user_id_aiscout: 'user_id_aiscout',
  emailVerified: 'emailVerified',
  image: 'image',
  business_card_affiliation_id_aiscout: 'business_card_affiliation_id_aiscout'
};

exports.Prisma.Access_token_aiscoutScalarFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  access_token: 'access_token',
  access_token_expires_at: 'access_token_expires_at',
  token_type: 'token_type',
  access_token_expires_in: 'access_token_expires_in',
  access_token_issued_at: 'access_token_issued_at',
  refresh_token: 'refresh_token',
  refresh_token_expires_at: 'refresh_token_expires_at',
  refresh_token_issued_at: 'refresh_token_issued_at'
};

exports.Prisma.User_accountsScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state',
  email: 'email'
};

exports.Prisma.User_sessionsScalarFieldEnum = {
  id: 'id',
  sessionToken: 'sessionToken',
  userId: 'userId',
  expires: 'expires'
};

exports.Prisma.User_verification_tokensScalarFieldEnum = {
  identifier: 'identifier',
  token: 'token',
  expires: 'expires',
  createdAt: 'createdAt'
};

exports.Prisma.User_login_recordsScalarFieldEnum = {
  id: 'id',
  login_type: 'login_type',
  user_id: 'user_id',
  email: 'email',
  request_ip: 'request_ip',
  location: 'location',
  created_at: 'created_at'
};

exports.Prisma.Performance_lab_metric_measurement_typesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  unit_name: 'unit_name',
  unit_short_name: 'unit_short_name',
  high_is_best: 'high_is_best',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_metric_measurement_type_benchmarksScalarFieldEnum = {
  id: 'id',
  station_group_id: 'station_group_id',
  station_id: 'station_id',
  metric_id: 'metric_id',
  measurement_type_id: 'measurement_type_id',
  value: 'value',
  gender: 'gender'
};

exports.Prisma.Performance_lab_metric_entriesScalarFieldEnum = {
  id: 'id',
  session_id: 'session_id',
  station_id: 'station_id',
  metric_id: 'metric_id',
  player_id: 'player_id',
  player_id_aiscout: 'player_id_aiscout',
  created: 'created',
  modified: 'modified',
  scored_by_user_id: 'scored_by_user_id'
};

exports.Prisma.Performance_lab_metricsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  description: 'description',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_station_metric_mappingsScalarFieldEnum = {
  station_id: 'station_id',
  metric_id: 'metric_id',
  is_additional: 'is_additional',
  sort_order: 'sort_order'
};

exports.Prisma.Performance_lab_metric_measurement_mappingsScalarFieldEnum = {
  metric_id: 'metric_id',
  measurement_type_id: 'measurement_type_id'
};

exports.Prisma.Performance_lab_metric_rating_mappingsScalarFieldEnum = {
  metric_id: 'metric_id',
  rating_id: 'rating_id'
};

exports.Prisma.Performance_lab_metric_rating_typesScalarFieldEnum = {
  id: 'id',
  name: 'name',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_session_metric_exclusionsScalarFieldEnum = {
  session_id: 'session_id',
  metric_id: 'metric_id',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_session_station_exclusionsScalarFieldEnum = {
  session_id: 'session_id',
  station_id: 'station_id',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_metric_entry_ratingsScalarFieldEnum = {
  entry_id: 'entry_id',
  rating_type_id: 'rating_type_id',
  value: 'value',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_metric_entry_measurementsScalarFieldEnum = {
  entry_id: 'entry_id',
  measurement_type_id: 'measurement_type_id',
  value: 'value',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_station_groupsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_stationsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id: 'pro_club_id',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  description: 'description',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_stations_group_mappingsScalarFieldEnum = {
  station_group_id: 'station_group_id',
  station_id: 'station_id',
  sort_order: 'sort_order'
};

exports.Prisma.Awsdms_apply_exceptionsScalarFieldEnum = {
  TASK_NAME: 'TASK_NAME',
  TABLE_OWNER: 'TABLE_OWNER',
  TABLE_NAME: 'TABLE_NAME',
  ERROR_TIME: 'ERROR_TIME',
  STATEMENT: 'STATEMENT',
  ERROR: 'ERROR',
  error_id: 'error_id',
  notified_at: 'notified_at'
};

exports.Prisma.Export_jobsScalarFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  status: 'status',
  progress: 'progress',
  job_type: 'job_type',
  export_data: 'export_data',
  file_path: 'file_path',
  should_send_email: 'should_send_email',
  created: 'created',
  modified: 'modified',
  completed_at: 'completed_at',
  failed_reason: 'failed_reason'
};

exports.Prisma.Match_report_actionsScalarFieldEnum = {
  id: 'id',
  name: 'name',
  pro_club_id_aiscout: 'pro_club_id_aiscout',
  sort_order: 'sort_order',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Match_report_action_entriesScalarFieldEnum = {
  id: 'id',
  summary_report_draft_id_aiscout: 'summary_report_draft_id_aiscout',
  summary_report_id_aiscout: 'summary_report_id_aiscout',
  action_id: 'action_id',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.Performance_lab_session_notesScalarFieldEnum = {
  id: 'id',
  user_id: 'user_id',
  session_id: 'session_id',
  pro_club_id: 'pro_club_id',
  note: 'note',
  created: 'created',
  modified: 'modified'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.aiscout_sign_up_tier_type_data = exports.$Enums.aiscout_sign_up_tier_type_data = {
  UNSPECIFIED: 'UNSPECIFIED',
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3'
};

exports.aiscout_gender_player = exports.$Enums.aiscout_gender_player = {
  UNSPECIFIED: 'UNSPECIFIED',
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

exports.aiscout_preferred_foot = exports.$Enums.aiscout_preferred_foot = {
  NONE: 'NONE',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  BOTH: 'BOTH'
};

exports.aiscout_secondary_contact_relationship_type = exports.$Enums.aiscout_secondary_contact_relationship_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  COACH: 'COACH',
  TEACHER: 'TEACHER',
  FAMILY_MEMBER: 'FAMILY_MEMBER'
};

exports.aiscout_pro_club_signed_type = exports.$Enums.aiscout_pro_club_signed_type = {
  AVAILABLE: 'AVAILABLE',
  TRIAL: 'TRIAL',
  SIGNED: 'SIGNED',
  SCHOLAR: 'SCHOLAR',
  RELEASE: 'RELEASE'
};

exports.aiscout_sign_up_tier_type_player = exports.$Enums.aiscout_sign_up_tier_type_player = {
  UNSPECIFIED: 'UNSPECIFIED',
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3'
};

exports.aiscout_share_type = exports.$Enums.aiscout_share_type = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

exports.aiscout_media_type = exports.$Enums.aiscout_media_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO'
};

exports.aiscout_expertise_type = exports.$Enums.aiscout_expertise_type = {
  UNKNOWN: 'UNKNOWN',
  HEAD_SCOUT: 'HEAD_SCOUT'
};

exports.aiscout_player_report_draft_status = exports.$Enums.aiscout_player_report_draft_status = {
  UNSPECIFIED: 'UNSPECIFIED',
  INITIATED: 'INITIATED',
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED'
};

exports.aiscout_batch_transcription_status = exports.$Enums.aiscout_batch_transcription_status = {
  UNSPECIFIED: 'UNSPECIFIED',
  NOT_STARTED: 'NOT_STARTED',
  PROCESSING: 'PROCESSING',
  PROCESSING_COMPLETED: 'PROCESSING_COMPLETED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  EMPTY_VOICE_NOTES: 'EMPTY_VOICE_NOTES'
};

exports.aiscout_player_report_participant_type = exports.$Enums.aiscout_player_report_participant_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  TEAM: 'TEAM',
  PLAYER: 'PLAYER'
};

exports.aiscout_summary_report_draft_status = exports.$Enums.aiscout_summary_report_draft_status = {
  UNSPECIFIED: 'UNSPECIFIED',
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED'
};

exports.aiscout_team_status_type = exports.$Enums.aiscout_team_status_type = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISABLED: 'DISABLED'
};

exports.aiscout_availability_type = exports.$Enums.aiscout_availability_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  AVAILABLE: 'AVAILABLE',
  AWAY: 'AWAY',
  INJURED: 'INJURED',
  OFFSICK: 'OFFSICK',
  GAME: 'GAME'
};

exports.aiscout_measurement_fact_type = exports.$Enums.aiscout_measurement_fact_type = {
  NONE: 'NONE',
  BALL_SIZE: 'BALL_SIZE'
};

exports.aiscout_trial_type = exports.$Enums.aiscout_trial_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  PROFESSIONAL_CLUB_TRIAL: 'PROFESSIONAL_CLUB_TRIAL',
  NATIONAL_RATING_TRIAL: 'NATIONAL_RATING_TRIAL',
  FA_NATIONAL_SQUAD_TRIAL: 'FA_NATIONAL_SQUAD_TRIAL',
  SPONSORED_TRIAL: 'SPONSORED_TRIAL',
  EDUCATIONAL_ACADEMY_TRIAL: 'EDUCATIONAL_ACADEMY_TRIAL',
  AFFILIATE_TRIAL: 'AFFILIATE_TRIAL',
  MAJOR_LEAGUE_SOCCER_TRIAL: 'MAJOR_LEAGUE_SOCCER_TRIAL',
  ACADEMY_STANDARD_TRIAL: 'ACADEMY_STANDARD_TRIAL',
  ACADEMY_ANALYSIS_TRIAL: 'ACADEMY_ANALYSIS_TRIAL',
  DRILL_LIBRARY_TRIAL: 'DRILL_LIBRARY_TRIAL',
  MAJOR_LEAGUE_SOCCER_WAY_TRIAL: 'MAJOR_LEAGUE_SOCCER_WAY_TRIAL',
  ACADEMY_TRIAL: 'ACADEMY_TRIAL'
};

exports.aiscout_skilldimension = exports.$Enums.aiscout_skilldimension = {
  ATHLETICISM: 'ATHLETICISM',
  TECHNICAL: 'TECHNICAL'
};

exports.aiscout_drillentryfeedbacktype = exports.$Enums.aiscout_drillentryfeedbacktype = {
  NONE: 'NONE',
  AUTOMATED: 'AUTOMATED',
  CUSTOM: 'CUSTOM'
};

exports.aiscout_drillentrystatus = exports.$Enums.aiscout_drillentrystatus = {
  ANALYSED: 'ANALYSED',
  PENDING: 'PENDING',
  ERROR: 'ERROR'
};

exports.aiscout_drill_analyzed_notification_trial = exports.$Enums.aiscout_drill_analyzed_notification_trial = {
  UNSPECIFIED: 'UNSPECIFIED',
  PENDING: 'PENDING',
  ANALYZED: 'ANALYZED',
  VIDEO_PROCESSING_ERROR: 'VIDEO_PROCESSING_ERROR',
  VIDEO_FORMAT_NOT_SUPPORTED: 'VIDEO_FORMAT_NOT_SUPPORTED',
  VIDEO_DURATION_NOT_ACCEPTABLE: 'VIDEO_DURATION_NOT_ACCEPTABLE',
  FIRST_FRAME_IS_NOT_DETECTED: 'FIRST_FRAME_IS_NOT_DETECTED',
  NO_OBJECTS_DETECTED: 'NO_OBJECTS_DETECTED',
  NO_BALL_PERSON_DETECTED: 'NO_BALL_PERSON_DETECTED',
  PERSON_NOT_DETECTED: 'PERSON_NOT_DETECTED',
  MORE_THAN_ONE_BALL_DETECTED: 'MORE_THAN_ONE_BALL_DETECTED',
  BALL_NOT_DETECTED: 'BALL_NOT_DETECTED',
  CAMERA_MOVEMENT_DETECTED_IN_VERTICAL_DIRECTION: 'CAMERA_MOVEMENT_DETECTED_IN_VERTICAL_DIRECTION',
  CAMERA_MOVEMENT_DETECTED_IN_HORIZONTAL_DIRECTION: 'CAMERA_MOVEMENT_DETECTED_IN_HORIZONTAL_DIRECTION',
  BALL_NOT_IN_LINE_WITH_THE_PERSON: 'BALL_NOT_IN_LINE_WITH_THE_PERSON',
  PLEASE_EXECUTE_VALIDATION_FUNCTION: 'PLEASE_EXECUTE_VALIDATION_FUNCTION',
  VIDEO_NOT_ACCEPTABLE_AS_THE_CANDIDATE_IS_NOT_STANDING: 'VIDEO_NOT_ACCEPTABLE_AS_THE_CANDIDATE_IS_NOT_STANDING',
  VIDEO_NOT_ACCEPTABLE_AS_THE_PARTS_ARE_NOT_DETECTED: 'VIDEO_NOT_ACCEPTABLE_AS_THE_PARTS_ARE_NOT_DETECTED',
  VIDEO_NOT_ACCEPTABLE_SEQUENCE_MISMATCH: 'VIDEO_NOT_ACCEPTABLE_SEQUENCE_MISMATCH',
  MORE_THAN_ONE_PERSON_DETECTED: 'MORE_THAN_ONE_PERSON_DETECTED',
  ANALYSIS_COULD_NOT_BE_COMPLETED: 'ANALYSIS_COULD_NOT_BE_COMPLETED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};

exports.aiscout_gender_trial = exports.$Enums.aiscout_gender_trial = {
  UNSPECIFIED: 'UNSPECIFIED',
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

exports.aiscout_completion_type = exports.$Enums.aiscout_completion_type = {
  UNSPECIFIED: 'UNSPECIFIED',
  STANDARD: 'STANDARD',
  PROGRESSION: 'PROGRESSION'
};

exports.age_group_calculation_method = exports.$Enums.age_group_calculation_method = {
  calendar_year: 'calendar_year',
  uk_school_year: 'uk_school_year'
};

exports.json_field_type = exports.$Enums.json_field_type = {
  STRING: 'STRING',
  NUMBER: 'NUMBER'
};

exports.drill_metric_type = exports.$Enums.drill_metric_type = {
  single_drill: 'single_drill',
  drills_average: 'drills_average',
  completed_drills_average: 'completed_drills_average'
};

exports.luzmo_report_type = exports.$Enums.luzmo_report_type = {
  aiscout: 'aiscout',
  ailabs: 'ailabs'
};

exports.gender = exports.$Enums.gender = {
  male: 'male',
  female: 'female'
};

exports.performance_lab_reflexion_score_type = exports.$Enums.performance_lab_reflexion_score_type = {
  rawScore: 'rawScore',
  rfxnScore: 'rfxnScore'
};

exports.performance_lab_session_report_card_type = exports.$Enums.performance_lab_session_report_card_type = {
  compare: 'compare',
  progress: 'progress',
  measurement: 'measurement',
  donut: 'donut',
  elite: 'elite'
};

exports.performance_lab_trial_type = exports.$Enums.performance_lab_trial_type = {
  physical: 'physical',
  cognitive: 'cognitive'
};

exports.performance_lab_session_type = exports.$Enums.performance_lab_session_type = {
  pre_season: 'pre_season',
  in_season: 'in_season',
  post_season: 'post_season',
  event: 'event',
  demo: 'demo',
  adnoc: 'adnoc'
};

exports.aiscout_notify_job_type = exports.$Enums.aiscout_notify_job_type = {
  TRIALS_TAB_VIEW: 'TRIALS_TAB_VIEW',
  PLAYER_PROFILE_EDIT_VIEW: 'PLAYER_PROFILE_EDIT_VIEW'
};

exports.rule_engine_filter_type = exports.$Enums.rule_engine_filter_type = {
  full_access: 'full_access',
  pro_club_drills: 'pro_club_drills',
  affiliation: 'affiliation',
  country: 'country'
};

exports.rule_engine_comparison_operator = exports.$Enums.rule_engine_comparison_operator = {
  equals: 'equals',
  not_equals: 'not_equals',
  less_than: 'less_than',
  greater_than: 'greater_than',
  less_than_or_equal_to: 'less_than_or_equal_to',
  greater_than_or_equal_to: 'greater_than_or_equal_to',
  includes: 'includes',
  excludes: 'excludes'
};

exports.rule_engine_logical_operator = exports.$Enums.rule_engine_logical_operator = {
  and: 'and',
  or: 'or'
};

exports.user_permissions_group = exports.$Enums.user_permissions_group = {
  BenchmarkTurk: 'BenchmarkTurk',
  SuperTurk: 'SuperTurk',
  Turk: 'Turk',
  TurkAdmin: 'TurkAdmin',
  AiLabsCast: 'AiLabsCast'
};

exports.unit_system = exports.$Enums.unit_system = {
  metric: 'metric',
  imperial: 'imperial'
};

exports.user_login_type = exports.$Enums.user_login_type = {
  aiScoutCalcey: 'aiScoutCalcey',
  aiScout: 'aiScout',
  CastLoginCode: 'CastLoginCode'
};

exports.export_job_status = exports.$Enums.export_job_status = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED'
};

exports.export_job_type = exports.$Enums.export_job_type = {
  LAB_SESSION_DATA: 'LAB_SESSION_DATA'
};

exports.Prisma.ModelName = {
  aiscout_affiliation: 'aiscout_affiliation',
  aiscout_affiliation_tag: 'aiscout_affiliation_tag',
  aiscout_age_group: 'aiscout_age_group',
  aiscout_country: 'aiscout_country',
  aiscout_county: 'aiscout_county',
  aiscout_ethnicity: 'aiscout_ethnicity',
  aiscout_nationality: 'aiscout_nationality',
  aiscout_passport_authority: 'aiscout_passport_authority',
  aiscout_position: 'aiscout_position',
  aiscout_sign_up_tier: 'aiscout_sign_up_tier',
  aiscout_term_agreement: 'aiscout_term_agreement',
  aiscout_player: 'aiscout_player',
  aiscout_player_affiliation: 'aiscout_player_affiliation',
  aiscout_player_country: 'aiscout_player_country',
  aiscout_player_highlight_request: 'aiscout_player_highlight_request',
  aiscout_player_nationality: 'aiscout_player_nationality',
  aiscout_player_passport_authority: 'aiscout_player_passport_authority',
  aiscout_player_position: 'aiscout_player_position',
  aiscout_player_sign_up_agreement: 'aiscout_player_sign_up_agreement',
  aiscout_player_sign_up_agreement_reason: 'aiscout_player_sign_up_agreement_reason',
  aiscout_reel: 'aiscout_reel',
  aiscout_scout: 'aiscout_scout',
  aiscout_scout_achievement: 'aiscout_scout_achievement',
  aiscout_scout_business_card: 'aiscout_scout_business_card',
  aiscout_scout_expertise: 'aiscout_scout_expertise',
  aiscout_scout_player_follower: 'aiscout_scout_player_follower',
  aiscout_player_report: 'aiscout_player_report',
  aiscout_player_report_draft: 'aiscout_player_report_draft',
  aiscout_player_report_participant: 'aiscout_player_report_participant',
  aiscout_summary_report: 'aiscout_summary_report',
  aiscout_summary_report_draft: 'aiscout_summary_report_draft',
  aiscout_academy_team: 'aiscout_academy_team',
  aiscout_academy_team_player: 'aiscout_academy_team_player',
  aiscout_coach: 'aiscout_coach',
  aiscout_pro_club: 'aiscout_pro_club',
  aiscout_team: 'aiscout_team',
  aiscout_team_player: 'aiscout_team_player',
  aiscout_team_type: 'aiscout_team_type',
  aiscout_training_session: 'aiscout_training_session',
  aiscout_training_session_player: 'aiscout_training_session_player',
  aiscout_drill: 'aiscout_drill',
  aiscout_drill_entry: 'aiscout_drill_entry',
  aiscout_drill_entry_measurement: 'aiscout_drill_entry_measurement',
  aiscout_drill_entry_measurement_type: 'aiscout_drill_entry_measurement_type',
  aiscout_drill_entry_rating: 'aiscout_drill_entry_rating',
  aiscout_drill_entry_rating_type: 'aiscout_drill_entry_rating_type',
  aiscout_drill_library_item: 'aiscout_drill_library_item',
  aiscout_entry_measurement_type: 'aiscout_entry_measurement_type',
  aiscout_entry_rating_type: 'aiscout_entry_rating_type',
  aiscout_trial: 'aiscout_trial',
  aiscout_trial_category: 'aiscout_trial_category',
  aiscout_trial_drill: 'aiscout_trial_drill',
  aiscout_user_setting: 'aiscout_user_setting',
  control_centre_pro_clubs: 'control_centre_pro_clubs',
  control_centre_pro_club_settings: 'control_centre_pro_club_settings',
  control_centre_pro_club_player_metadata_schemas: 'control_centre_pro_club_player_metadata_schemas',
  drill_metric_graphs: 'drill_metric_graphs',
  drill_metric_graph_metrics: 'drill_metric_graph_metrics',
  drill_metrics: 'drill_metrics',
  drill_metric_drills: 'drill_metric_drills',
  luzmo_report_role_permissions: 'luzmo_report_role_permissions',
  luzmo_report_sections: 'luzmo_report_sections',
  luzmo_reports: 'luzmo_reports',
  hawkin_dynamics_metrics: 'hawkin_dynamics_metrics',
  hawkin_dynamics_tags: 'hawkin_dynamics_tags',
  hawkin_dynamics_tests: 'hawkin_dynamics_tests',
  match_report_decision_status_entries: 'match_report_decision_status_entries',
  match_report_decision_statuses: 'match_report_decision_statuses',
  performance_lab_hawkin_dynamics_measurements: 'performance_lab_hawkin_dynamics_measurements',
  performance_lab_hawkin_dynamics_measurements_tags: 'performance_lab_hawkin_dynamics_measurements_tags',
  performance_lab_measurement_formulas: 'performance_lab_measurement_formulas',
  performance_lab_measurements: 'performance_lab_measurements',
  performance_lab_measurement_scoring_lookups: 'performance_lab_measurement_scoring_lookups',
  performance_lab_player_maturation: 'performance_lab_player_maturation',
  performance_lab_player_reflexion_test_attempts: 'performance_lab_player_reflexion_test_attempts',
  performance_lab_reflexion_test_metrics: 'performance_lab_reflexion_test_metrics',
  performance_lab_reflexion_tests: 'performance_lab_reflexion_tests',
  performance_lab_report_cards: 'performance_lab_report_cards',
  performance_lab_report_card_benchmarks: 'performance_lab_report_card_benchmarks',
  performance_lab_report_card_benchmark_types: 'performance_lab_report_card_benchmark_types',
  performance_lab_report_card_tips: 'performance_lab_report_card_tips',
  performance_lab_report_card_measurements: 'performance_lab_report_card_measurements',
  performance_lab_report_card_slots: 'performance_lab_report_card_slots',
  performance_lab_session_measurements: 'performance_lab_session_measurements',
  performance_lab_session_players: 'performance_lab_session_players',
  performance_lab_session_report_card_slots: 'performance_lab_session_report_card_slots',
  performance_lab_session_themes: 'performance_lab_session_themes',
  performance_lab_sessions: 'performance_lab_sessions',
  player_game_clips: 'player_game_clips',
  player_management_action_log: 'player_management_action_log',
  player_management_actions: 'player_management_actions',
  player_management_entries: 'player_management_entries',
  player_management_entry_log: 'player_management_entry_log',
  player_management_entry_tags: 'player_management_entry_tags',
  player_management_lists: 'player_management_lists',
  player_management_list_type_rule_actions: 'player_management_list_type_rule_actions',
  player_management_list_type_rule_notifications: 'player_management_list_type_rule_notifications',
  player_management_list_type_rule_tags_types: 'player_management_list_type_rule_tags_types',
  player_management_list_types: 'player_management_list_types',
  player_management_notifications: 'player_management_notifications',
  player_management_notification_types: 'player_management_notification_types',
  player_management_tags: 'player_management_tags',
  player_management_tags_types: 'player_management_tags_types',
  player_manual_observations: 'player_manual_observations',
  player_manual_observation_entries: 'player_manual_observation_entries',
  player_metadata: 'player_metadata',
  player_notes: 'player_notes',
  player_notifications: 'player_notifications',
  player_position_group_to_player_position: 'player_position_group_to_player_position',
  player_position_groups: 'player_position_groups',
  player_report_status_entries: 'player_report_status_entries',
  player_report_statuses: 'player_report_statuses',
  player_report_voice_note_tag_entries: 'player_report_voice_note_tag_entries',
  player_report_voice_note_tags: 'player_report_voice_note_tags',
  pro_club_partnership_programme_players: 'pro_club_partnership_programme_players',
  recruitment_centre_rule_engine: 'recruitment_centre_rule_engine',
  saved_searches: 'saved_searches',
  seasons: 'seasons',
  super_turk_drills: 'super_turk_drills',
  turk_benchmark_affiliations: 'turk_benchmark_affiliations',
  turk_benchmark_affiliation_users: 'turk_benchmark_affiliation_users',
  turk_error_code_groups: 'turk_error_code_groups',
  turk_error_codes: 'turk_error_codes',
  turk_queue: 'turk_queue',
  user_pro_club_access: 'user_pro_club_access',
  user_permission_groups: 'user_permission_groups',
  user_roles: 'user_roles',
  user_settings: 'user_settings',
  users: 'users',
  access_token_aiscout: 'access_token_aiscout',
  user_accounts: 'user_accounts',
  user_sessions: 'user_sessions',
  user_verification_tokens: 'user_verification_tokens',
  user_login_records: 'user_login_records',
  performance_lab_metric_measurement_types: 'performance_lab_metric_measurement_types',
  performance_lab_metric_measurement_type_benchmarks: 'performance_lab_metric_measurement_type_benchmarks',
  performance_lab_metric_entries: 'performance_lab_metric_entries',
  performance_lab_metrics: 'performance_lab_metrics',
  performance_lab_station_metric_mappings: 'performance_lab_station_metric_mappings',
  performance_lab_metric_measurement_mappings: 'performance_lab_metric_measurement_mappings',
  performance_lab_metric_rating_mappings: 'performance_lab_metric_rating_mappings',
  performance_lab_metric_rating_types: 'performance_lab_metric_rating_types',
  performance_lab_session_metric_exclusions: 'performance_lab_session_metric_exclusions',
  performance_lab_session_station_exclusions: 'performance_lab_session_station_exclusions',
  performance_lab_metric_entry_ratings: 'performance_lab_metric_entry_ratings',
  performance_lab_metric_entry_measurements: 'performance_lab_metric_entry_measurements',
  performance_lab_station_groups: 'performance_lab_station_groups',
  performance_lab_stations: 'performance_lab_stations',
  performance_lab_stations_group_mappings: 'performance_lab_stations_group_mappings',
  awsdms_apply_exceptions: 'awsdms_apply_exceptions',
  export_jobs: 'export_jobs',
  match_report_actions: 'match_report_actions',
  match_report_action_entries: 'match_report_action_entries',
  performance_lab_session_notes: 'performance_lab_session_notes'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
